import { render, staticRenderFns } from "./playvideomodal.vue?vue&type=template&id=39c12904&scoped=true&"
import script from "./playvideomodal.vue?vue&type=script&lang=js&"
export * from "./playvideomodal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c12904",
  null
  
)

export default component.exports