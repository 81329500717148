<template>
    <div class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">

                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title">
                                    {{ $t("videos_videos") }}
                                </h3>
                                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                                <span class="kt-subheader__desc">{{ $t("videos_subTitle") }}</span>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">

                            <div class="row">
                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/quick_deployment_guidelines.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/__tIrzXCAFM" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title1") }}</span>
                                            <span><i class="la la-clock-o"></i> 3:03</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/01-Front-page-first-login.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/yd4o2gt5xnk" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title2") }}</span>
                                            <span><i class="la la-clock-o"></i> 2:05</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/02-Front-page-site_creation.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/sug8_iwSEOU" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title3") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:43</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/03-Front-page-site_location.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/ECpN93ug_8s" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title4") }}</span>
                                            <span><i class="la la-clock-o"></i> 0:46</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/04-Front-page-building_floors.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/4xFMh3Uf_hk" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title5") }}</span>
                                            <span><i class="la la-clock-o"></i> 2:07</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/05-Front-page-indoor_area.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/HkYw9ZJcR2A" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title6") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:22</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/06-Front-page-ble_gateway.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/Io0unnmon3w" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title7") }}</span>
                                            <span><i class="la la-clock-o"></i> 2:47</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/07-Front-page-autocal.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/GZbVgnKluiA" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title8") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:48</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/08-Front-page-mobile_tags.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/48SSeVEjsfI" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title9") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:03</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/09-Front-page-assets.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/ef9UYdwTqU8" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title10") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:44</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/10-Front-page-geofence.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/8i4TATKtvxg" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title11") }}</span>
                                            <span><i class="la la-clock-o"></i> 2:33</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/11-Front-page-walls.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/Z6ol-EYz9fE" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title12") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:19</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/12-Front-page-production.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/Tcjo7I7L7v4" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title13") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:13</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/13-Front-page-tests.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/YuZ90EephZU" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title14") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:47</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/14-Front-page-asset_search.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/Xq9zg85p11A" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title15") }}</span>
                                            <span><i class="la la-clock-o"></i> 2:21</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/15-Front-page-mobile.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/kyT3_yJO3ag" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title16") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:44</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/stellar_ble_gateways_status.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/f3iEh8tJkT0" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title17") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:51</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/autocalibration_beacons_status.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/Nw_PXQBiMrE" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title18") }}</span>
                                            <span><i class="la la-clock-o"></i> 3:21</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/ble_tags_status.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/S9vvwKYv7V0" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title19") }}</span>
                                            <span><i class="la la-clock-o"></i> 1:45</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/16-Front-page-new_subscription.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/4HkUN7PpCuI" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title20") }}</span>
                                            <span><i class="la la-clock-o"></i> 4:03</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/17-Front-page-subscription_extended.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/CoC8uZAHP-g" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title21") }}</span>
                                            <span><i class="la la-clock-o"></i> 4:02</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/18-Front-page-expanding_subscription.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/1AWI3B9Jtg8" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title22") }}</span>
                                            <span><i class="la la-clock-o"></i> 3:42</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-3">
                                    <div class="kt-portlet kt-portlet--mobile">
                                        <div class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides videoBG" style="background-image: url('/assets/videos/19-Front-page-transfer_of_licenses.png');">
                                            <div class="kt-widget17__chart bgDiv">
                                                <i data-url="https://www.youtube.com/embed/OHyrqy9g0PM" class="la la-play-circle iconPlay"></i>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span class="videoTitle">OmniAccess Stellar Asset Tracking - {{ $t("videos_title23") }}</span>
                                            <span><i class="la la-clock-o"></i> 5:29</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <!-- end:: Content -->

                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->

                    <app-playvideo :youtubeUrl="youtubeUrl"></app-playvideo>

                </div>

            </div>

        </div>

    </div>
</template>

<script>
import PlayVideoModal from "../modals/playvideomodal.vue";
export default {
    data() {
        return {
            youtubeUrl: ""
        };
    },
    mounted: function() {
        var self = this;
        $(".iconPlay").off().on("click", function() {
            self.youtubeUrl = $(this).attr("data-url");
            $("#playVideoModal").modal("show");
        });
    },
    components: {
        "app-playvideo": PlayVideoModal
    }
};
</script>

<style scoped>
.baseTemplate {
  display: contents;
}
.videoBG {
    background-size: cover;
}
.videoTitle {
    color: #5867dd;
}
.iconPlay {
    opacity: 0.7;
    margin: 0 auto;
    display: table;
    font-size: 106px;
    color: white;
    cursor: pointer;
}
.iconPlay:hover {
    opacity: 0.9;
    font-size: 115px;
}
.bgDiv {
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
