<template>
    <div class="modal fade show" id="playVideoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document" style="max-width: 850px;">
            <div class="modal-content">
                <div class="modal-body" style="padding: 0;margin-bottom: -5px;">
                    <iframe width="850" height="470" :src="localURL" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="modal-footer">
                    <button id="closeSearchResultButton" @click="onClose" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            localURL: null
        };
    },
    methods: {
        onClose() {
            this.localURL = null;
        }
    },
    props: ['youtubeUrl'],
    watch: {
        youtubeUrl: function(youtubeUrl) {
            this.localURL = youtubeUrl;
        }
    }
};
</script>

<style scoped></style>
